import React, { useEffect } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import RequiresAuth from "app/RequiresAuth";
import LoginContainer from "routes/Login";
import Registration from "routes/Registration";
import NotFound from "routes/NotFound";
import { AppContainer as HotLoadBoundary } from "react-hot-loader";
import "react-dates/initialize";
import "font-awesome/css/font-awesome.min.css";
import "../static-new/styles/main.css";
import AppContainer from "./App";
import history from "../history";
import { appInsights, reactPlugin } from 'appInsights';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

const Root = ({ store }) => {

  useEffect(() => {
    appInsights.trackPageView();
  }, []);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary
        onError={() => <h1>Something went wrong</h1>}
        appInsights={reactPlugin}
      >
        <Provider store={store}>        
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/authentication"
                  name="Authentication"
                  component={LoginContainer}
                />
                <Route
                  exact
                  path="/registration"
                  name="Registration"
                  component={Registration}
                />
                <Route
                  exact
                  path="/404"
                  name="404 Not Found"
                  component={NotFound}
                />
                <HotLoadBoundary>
                  <Route
                    path="/"
                    name="app"
                    component={RequiresAuth(AppContainer)}
                  />
                </HotLoadBoundary>
              </Switch>
            </Router>    
        </Provider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
};

export default Root;
